<template>
	<div class="relative">
		<div class="vx-navbar-wrapper" :class="classObj">
			<vs-navbar
				class="vx-navbar navbar-custom navbar-skelton"
				:color="navbarColorLocal"
				:class="textColor">
				<vs-spacer />
				<div slot="title">
					<div class="logo-top-left pointer" @click="home">
						<!-- <img src="@/assets/images/logo/koblatil.png" alt="Kobla" class="pt-3 pl-3"/> -->
					</div>
				</div>
				<profile-drop-down-user />
			</vs-navbar>
		</div>
	</div>
</template>

<script>
import ProfileDropDownUser from './components/ProfileDropDownUser.vue';

export default {
  name: 'the-navbar-vertical',
  props: {
    navbarColor: {
      type: String,
      default: '#fff',
    },
  },
  components: {
    ProfileDropDownUser,
  },
  computed: {
    navbarColorLocal() {
      return this.$store.state.theme === 'dark'
				&& this.navbarColor === '#fff'
        ? '#10163a'
        : this.navbarColor;
    },
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth;
    },
    textColor() {
      return {
        'text-white':
					(this.navbarColor !== '#10163a'
						&& this.$store.state.theme === 'dark')
					|| (this.navbarColor !== '#fff'
						&& this.$store.state.theme !== 'dark'),
      };
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },

    // NAVBAR STYLE
    classObj() {
      let className = '';
      if (this.verticalNavMenuWidth === 'default') className = 'navbar-default';
      if (this.verticalNavMenuWidth === 'reduced') className = 'navbar-reduced';
      if (this.verticalNavMenuWidth) className = 'navbar-full';
      return className;
    },
  },
  methods: {
    showSidebar() {
      this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true);
    },
    home() {
      this.$router.push('/').catch(() => {});
    },
  },
};
</script>
