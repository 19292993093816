/* =========================================================================================
	File Name: sidebarItems.js
	Description: Sidebar Items list. Add / Remove menu items from here.
	Strucutre:
		url     => router path
		name    => name to display in sidebar
		slug    => router path name
		icon    => Feather Icon component/icon name
		tag     => text to display on badge
		tagColor  => class to apply on badge element
		i18n    => Internationalization
		submenu   => submenu of current item (current item will become dropdown )
		NOTE: Submenu don't have any icon(you can add icon if u want to display)
		isDisabled  => disable sidebar item/group
========================================================================================== */

export default [
  {
    url: '/events',
    name: 'Events',
    slug: 'events',
    icon: 'ApertureIcon',
    i18n: 'Events',
  },
  {
    url: '/manage/teams',
    name: 'Teams',
    slug: 'teams',
    icon: 'UsersIcon',
    i18n: 'Teams',
  },
  {
    url: '/manage/calendar',
    name: 'Calendar',
    slug: 'calendar',
    icon: 'CalendarIcon',
    i18n: 'Calendar',
  },
  {
    url: '/manage/announcement',
    name: 'announcement',
    slug: 'announcement',
    icon: 'MessageCircleIcon',
    i18n: 'Announcement',
  },
  {
    url: '/manage/clients',
    name: 'Clients',
    slug: 'clients',
    icon: 'UsersIcon',
    i18n: 'Clients',
  },
  {
    url: '/settings',
    name: 'Settings',
    slug: 'settings',
    icon: 'SettingsIcon',
    i18n: 'Settings',
  },
];
