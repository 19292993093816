<template>
	<div
    @contextmenu.prevent="showContextMenu($event)"
		class="vs-sidebar--item"
		:class="[
			{'vs-sidebar-item-active': activeLink},
			{'disabled-item pointer-events-none' : isDisabled}
		]"
		v-if="canSee">
		<router-link
			tabindex="-1"
			v-if="to"
			exact
			:class="[{'router-link-active': activeLink}]"
			:to="to"
			:target="target">
			<!-- custom icon -->
			<!-- teams -->
			<div v-if="isCustomIcon" class="flex items-center mr-3">
				<svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
					<path :fill="activeLink ? '#ffffff' : '#000000'" d="M16 17V19H2V17S2 13 9 13 16 17 16 17M12.5 7.5A3.5 3.5 0 1 0 9 11A3.5 3.5 0 0 0 12.5 7.5M15.94 13A5.32 5.32 0 0 1 18 17V19H22V17S22 13.37 15.94 13M15 4A3.39 3.39 0 0 0 13.07 4.59A5 5 0 0 1 13.07 10.41A3.39 3.39 0 0 0 15 11A3.5 3.5 0 0 0 15 4Z" />
				</svg>
			</div>
			<!-- icon MDI -->
			<div v-if="iconMdi === 'Settings'" class="flex items-center mr-2">
				<svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
					<path :fill="activeLink ? '#ffffff' : '#000000'" d="M3 17.25V21H6.75L17.81 9.93L14.06 6.18L3 17.25M22.61 18.36L18.36 22.61L13.16 17.41L14.93 15.64L15.93 16.64L18.4 14.16L19.82 15.58L18.36 17L19.42 18L20.84 16.6L22.61 18.36M6.61 10.83L1.39 5.64L5.64 1.39L7.4 3.16L4.93 5.64L6 6.7L8.46 4.22L9.88 5.64L8.46 7.05L9.46 8.05L6.61 10.83M20.71 7C21.1 6.61 21.1 6 20.71 5.59L18.37 3.29C18 2.9 17.35 2.9 16.96 3.29L15.12 5.12L18.87 8.87L20.71 7Z" />
				</svg>
			</div>
			<div v-if="iconMdi === 'Info'" class="flex items-center mr-2">
				<svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
					<path :fill="activeLink ? '#ffffff' : '#000000'" d="M12.3 7.29C12.5 7.11 12.74 7 13 7C13.27 7 13.5 7.11 13.71 7.29C13.9 7.5 14 7.74 14 8C14 8.27 13.9 8.5 13.71 8.71C13.5 8.9 13.27 9 13 9C12.74 9 12.5 8.9 12.3 8.71C12.11 8.5 12 8.27 12 8C12 7.74 12.11 7.5 12.3 7.29M9.8 11.97C9.8 11.97 11.97 10.25 12.76 10.18C13.5 10.12 13.35 10.97 13.28 11.41L13.27 11.47C13.13 12 12.96 12.64 12.79 13.25C12.41 14.64 12.04 16 12.13 16.25C12.23 16.59 12.85 16.16 13.3 15.86C13.36 15.82 13.41 15.78 13.46 15.75C13.46 15.75 13.54 15.67 13.62 15.78C13.64 15.81 13.66 15.84 13.68 15.86C13.77 16 13.82 16.05 13.7 16.13L13.66 16.15C13.44 16.3 12.5 16.96 12.12 17.2C11.71 17.47 10.14 18.37 10.38 16.62C10.59 15.39 10.87 14.33 11.09 13.5C11.5 12 11.68 11.32 10.76 11.91C10.39 12.13 10.17 12.27 10.04 12.36C9.93 12.44 9.92 12.44 9.85 12.31L9.82 12.25L9.77 12.17C9.7 12.07 9.7 12.06 9.8 11.97M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12M20 12C20 7.58 16.42 4 12 4C7.58 4 4 7.58 4 12C4 16.42 7.58 20 12 20C16.42 20 20 16.42 20 12Z" />
				</svg>
			</div>
			<div v-if="iconMdi === 'Participant'" class="flex items-center mr-2">
				<svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
					<path :fill="activeLink ? '#ffffff' : '#000000'" d="M12,5.5A3.5,3.5 0 0,1 15.5,9A3.5,3.5 0 0,1 12,12.5A3.5,3.5 0 0,1 8.5,9A3.5,3.5 0 0,1 12,5.5M5,8C5.56,8 6.08,8.15 6.53,8.42C6.38,9.85 6.8,11.27 7.66,12.38C7.16,13.34 6.16,14 5,14A3,3 0 0,1 2,11A3,3 0 0,1 5,8M19,8A3,3 0 0,1 22,11A3,3 0 0,1 19,14C17.84,14 16.84,13.34 16.34,12.38C17.2,11.27 17.62,9.85 17.47,8.42C17.92,8.15 18.44,8 19,8M5.5,18.25C5.5,16.18 8.41,14.5 12,14.5C15.59,14.5 18.5,16.18 18.5,18.25V20H5.5V18.25M0,20V18.5C0,17.11 1.89,15.94 4.45,15.6C3.86,16.28 3.5,17.22 3.5,18.25V20H0M24,20H20.5V18.25C20.5,17.22 20.14,16.28 19.55,15.6C22.11,15.94 24,17.11 24,18.5V20Z" />
				</svg>
			</div>
			<div v-if="iconMdi === 'Q&A'" class="flex items-center mr-2">
				<svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
					<path :fill="activeLink ? '#ffffff' : '#000000'" d="M17,12V3A1,1 0 0,0 16,2H3A1,1 0 0,0 2,3V17L6,13H16A1,1 0 0,0 17,12M21,6H19V15H6V17A1,1 0 0,0 7,18H18L22,22V7A1,1 0 0,0 21,6Z" />
				</svg>
			</div>
			<div v-if="iconMdi === 'Polls'" class="flex items-center mr-2">
				<svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
					<path :fill="activeLink ? '#ffffff' : '#000000'" d="M9 17H7V10H9V17M13 17H11V7H13V17M17 17H15V13H17V17M19 19H5V5H19V19.1M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3Z" />
				</svg>
			</div>
			<div v-if="iconMdi === 'Analytics'" class="flex items-center mr-2">
				<svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
					<path :fill="activeLink ? '#ffffff' : '#000000'" d="M3.5,18.5L9.5,12.5L13.5,16.5L22,6.92L20.59,5.5L13.5,13.5L9.5,9.5L2,17L3.5,18.5Z" />
				</svg>
			</div>

			<!-- vuesax / feather icon -->
			<vs-icon v-if="!featherIcon && !isCustomIcon" :icon-pack="iconPack" :icon="icon" />
			<feather-icon v-if="featherIcon && !isCustomIcon" :class="{'w-3 h-3': iconSmall, 'text-black': !activeLink, 'text-white': activeLink}" :icon="icon" />
			<slot />
		</router-link>

		<a v-else :target="target" :href="href" tabindex="-1">
			<!-- custom icon -->
			<!-- teams -->
			<div v-if="isCustomIcon" class="flex items-center mr-2">
				<svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
					<path :fill="activeLink ? '#ffffff' : '#000000'" d="M16 17V19H2V17S2 13 9 13 16 17 16 17M12.5 7.5A3.5 3.5 0 1 0 9 11A3.5 3.5 0 0 0 12.5 7.5M15.94 13A5.32 5.32 0 0 1 18 17V19H22V17S22 13.37 15.94 13M15 4A3.39 3.39 0 0 0 13.07 4.59A5 5 0 0 1 13.07 10.41A3.39 3.39 0 0 0 15 11A3.5 3.5 0 0 0 15 4Z" />
				</svg>
			</div>
			<!-- icon MDI -->
			<div v-if="iconMdi === 'Settings'" class="flex items-center mr-2">
				<svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
					<path :fill="activeLink ? '#ffffff' : '#000000'" d="M3 17.25V21H6.75L17.81 9.93L14.06 6.18L3 17.25M22.61 18.36L18.36 22.61L13.16 17.41L14.93 15.64L15.93 16.64L18.4 14.16L19.82 15.58L18.36 17L19.42 18L20.84 16.6L22.61 18.36M6.61 10.83L1.39 5.64L5.64 1.39L7.4 3.16L4.93 5.64L6 6.7L8.46 4.22L9.88 5.64L8.46 7.05L9.46 8.05L6.61 10.83M20.71 7C21.1 6.61 21.1 6 20.71 5.59L18.37 3.29C18 2.9 17.35 2.9 16.96 3.29L15.12 5.12L18.87 8.87L20.71 7Z" />
				</svg>
			</div>
			<div v-if="iconMdi === 'Info'" class="flex items-center mr-2">
				<svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
					<path :fill="activeLink ? '#ffffff' : '#000000'" d="M12.3 7.29C12.5 7.11 12.74 7 13 7C13.27 7 13.5 7.11 13.71 7.29C13.9 7.5 14 7.74 14 8C14 8.27 13.9 8.5 13.71 8.71C13.5 8.9 13.27 9 13 9C12.74 9 12.5 8.9 12.3 8.71C12.11 8.5 12 8.27 12 8C12 7.74 12.11 7.5 12.3 7.29M9.8 11.97C9.8 11.97 11.97 10.25 12.76 10.18C13.5 10.12 13.35 10.97 13.28 11.41L13.27 11.47C13.13 12 12.96 12.64 12.79 13.25C12.41 14.64 12.04 16 12.13 16.25C12.23 16.59 12.85 16.16 13.3 15.86C13.36 15.82 13.41 15.78 13.46 15.75C13.46 15.75 13.54 15.67 13.62 15.78C13.64 15.81 13.66 15.84 13.68 15.86C13.77 16 13.82 16.05 13.7 16.13L13.66 16.15C13.44 16.3 12.5 16.96 12.12 17.2C11.71 17.47 10.14 18.37 10.38 16.62C10.59 15.39 10.87 14.33 11.09 13.5C11.5 12 11.68 11.32 10.76 11.91C10.39 12.13 10.17 12.27 10.04 12.36C9.93 12.44 9.92 12.44 9.85 12.31L9.82 12.25L9.77 12.17C9.7 12.07 9.7 12.06 9.8 11.97M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12M20 12C20 7.58 16.42 4 12 4C7.58 4 4 7.58 4 12C4 16.42 7.58 20 12 20C16.42 20 20 16.42 20 12Z" />
				</svg>
			</div>
			<div v-if="iconMdi === 'Participant'" class="flex items-center mr-2">
				<svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
					<path :fill="activeLink ? '#ffffff' : '#000000'" d="M12,5.5A3.5,3.5 0 0,1 15.5,9A3.5,3.5 0 0,1 12,12.5A3.5,3.5 0 0,1 8.5,9A3.5,3.5 0 0,1 12,5.5M5,8C5.56,8 6.08,8.15 6.53,8.42C6.38,9.85 6.8,11.27 7.66,12.38C7.16,13.34 6.16,14 5,14A3,3 0 0,1 2,11A3,3 0 0,1 5,8M19,8A3,3 0 0,1 22,11A3,3 0 0,1 19,14C17.84,14 16.84,13.34 16.34,12.38C17.2,11.27 17.62,9.85 17.47,8.42C17.92,8.15 18.44,8 19,8M5.5,18.25C5.5,16.18 8.41,14.5 12,14.5C15.59,14.5 18.5,16.18 18.5,18.25V20H5.5V18.25M0,20V18.5C0,17.11 1.89,15.94 4.45,15.6C3.86,16.28 3.5,17.22 3.5,18.25V20H0M24,20H20.5V18.25C20.5,17.22 20.14,16.28 19.55,15.6C22.11,15.94 24,17.11 24,18.5V20Z" />
				</svg>
			</div>
			<div v-if="iconMdi === 'Q&A'" class="flex items-center mr-2">
				<svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
					<path :fill="activeLink ? '#ffffff' : '#000000'" d="M17,12V3A1,1 0 0,0 16,2H3A1,1 0 0,0 2,3V17L6,13H16A1,1 0 0,0 17,12M21,6H19V15H6V17A1,1 0 0,0 7,18H18L22,22V7A1,1 0 0,0 21,6Z" />
				</svg>
			</div>
			<div v-if="iconMdi === 'Polls'" class="flex items-center mr-2">
				<svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
					<path :fill="activeLink ? '#ffffff' : '#000000'" d="M9 17H7V10H9V17M13 17H11V7H13V17M17 17H15V13H17V17M19 19H5V5H19V19.1M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3Z" />
				</svg>
			</div>
			<div v-if="iconMdi === 'Analytics'" class="flex items-center mr-2">
				<svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
					<path :fill="activeLink ? '#ffffff' : '#000000'" d="M3.5,18.5L9.5,12.5L13.5,16.5L22,6.92L20.59,5.5L13.5,13.5L9.5,9.5L2,17L3.5,18.5Z" />
				</svg>
			</div>

			<!-- vuesax / feather icon -->
			<vs-icon v-if="!featherIcon && !isCustomIcon" :icon-pack="iconPack" :icon="icon" />
			<feather-icon v-if="featherIcon && !isCustomIcon" :class="{'w-3 h-3': iconSmall, 'text-black': !activeLink, 'text-white': activeLink}" :icon="icon" />
			<slot />
		</a>
    <div
      v-show="contextMenuVisible && activeLink"
      :style="{ left: `${contextMenuPosition.x}px`, top: `${contextMenuPosition.y}px` }"
      @click="handleContextMenuItemClick"
      class="context-menu"
    >
      <!-- Context menu items go here -->
      <div v-if="getPath(to) === 'qna'">{{ $t('Delete All Question (Reset)') }}</div>
      <div v-if="getPath(to) === 'polls'">{{ $t('Delete Polls (Reset)') }}</div>
    </div>
    <!-- prompt -->
    <vs-prompt
      :title="getTitle()"
      @accept="deleteRecord()"
      @close="closeDeleteConfirm()"
      @cancel="closeDeleteConfirm()"
      :accept-text="getAcceptText()"
      :disabled-outside="true"
      color="danger"
      :text="getText()"
      :is-valid="timerCountdown === 0"
      :active.sync="showConfirmDelete">
      <!-- reply section -->
      <div class="ml-2 relative">

      </div>
    </vs-prompt>

	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import questionsApi from '@/api/question';
import votingsApi from '@/api/voting';
import { isCanSee, getAxiosErrorMessage } from '../../../lib/helper';

export default {
  name: 'v-nav-menu-item',
  data() {
    return {
      contextMenuVisible: false,
      contextMenuPosition: { x: 0, y: 0 },
      timerCountdown: 5,
      intervalId: null,
      showConfirmDelete: false,
    };
  },
  props: {
    icon: { type: String, default: '' },
    iconMdi: { type: String, default: '' },
    iconSmall: { type: Boolean, default: false },
    iconPack: { type: String, default: 'material-icons' },
    href: { type: [String, null], default: '#' },
    to: { type: [String, Object, null], default: null },
    slug: { type: String, default: null },
    index: { type: [String, Number], default: null },
    featherIcon: { type: Boolean, default: true },
    target: { type: String, default: '_self' },
    isDisabled: { type: Boolean, default: false },
    eventID: { type: Number, default: null },
  },
  methods: {
    showContextMenu(event) {
      if (this.getPath(this.to) === 'polls' || this.getPath(this.to) === 'qna') {
        event.preventDefault();

        this.contextMenuVisible = true;

        this.contextMenuPosition = {
          x: event.clientX,
          y: event.clientY,
        };
        // Add a click event listener to the document to hide the context menu
        document.addEventListener('click', this.hideContextMenu);
      }
    },
    hideContextMenu() {
      // Hide the context menu
      this.contextMenuVisible = false;

      // Remove the click event listener from the document
      document.removeEventListener('click', this.hideContextMenu);
    },
    handleContextMenuItemClick() {
      this.showConfirmDelete = true;
      this.startCountdown();
      // Hide the context menu after clicking an item
      this.hideContextMenu();
    },
    getTitle() {
      return `${this.$t('Reset')} ${this.getPath(this.to) === 'polls' ? 'Polls' : 'Question'}`;
    },
    getText() {
      return `${this.$t('Are you sure want to delete all')} ${this.getPath(this.to) === 'polls' ? 'polls' : 'question'}?`;
    },
    getAcceptText() {
      return `${this.$t('Delete')} ${this.timerCountdown > 0 ? `(${this.timerCountdown})` : ''}`;
    },
    getPath() {
      // Example URL path
      const urlPath = this.to;
      // Split the path by '/'
      const pathSegments = urlPath.split('/');
      // Get the last non-empty segment (word)
      const lastWord = pathSegments.filter(segment => segment !== '').pop();
      return lastWord;
    },
    startCountdown() {
      this.intervalId = setInterval(() => {
        if (this.timerCountdown > 0) {
          this.timerCountdown--;
        } else {
          clearInterval(this.intervalId);
        }
      }, 1000); // Update every second
    },
    closeDeleteConfirm() {
      clearInterval(this.intervalId);
      this.timerCountdown = 5; // Reset to initial value
    },
    deleteRecord() {
      this.$vs.loading();
      const notifTitle = `${this.getPath(this.to) === 'polls' ? 'Polls' : 'Question'}`;
      const callback = (response) => {
        const message = response.message;
        this.$vs.notify({
          title: notifTitle,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success',
        });
        this.$vs.loading.close();
      };
      const errorCallback = (e) => {
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: notifTitle,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
        this.$vs.loading.close();
      };
      if (this.getPath(this.to) === 'polls') {
        votingsApi.resetAll(this.eventID, callback, errorCallback);
      } else {
        questionsApi.resetAll(this.eventID, callback, errorCallback);
      }
    },
  },
  computed: {
    ...mapGetters({
      user: 'user',
      role: 'role',
    }),
    canSee() {
      const canSee = isCanSee(this.to, this.$router, this.role);
      return canSee;
    },
    activeLink() {
      return !!(
        this.to === this.$route.path
				|| (this.$route.meta.parent === this.slug && this.to)
				|| (this.to === '/events' && this.$route.path === '/')
      );
    },
    isCustomIcon() {
      return this.icon === 'UsersIcon';
    },
  },
};
</script>
<style scoped>
.context-menu {
  position: fixed;
  background-color: #fff;
  border: 1px solid #ccccccb9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: 200px;
  padding: 12px 8px 12px 8px;
  z-index: 1000;
  cursor: pointer;
}

</style>
