<template>
	<vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
		<span class="cursor-pointer flex items-center i18n-locale">
			<img class="h-4 w-5 flag-icon" :src="i18nLocaleImg" :alt="$i18n.locale" />
			<span class="hidden sm:block ml-2">{{ getCurrentLocaleData.lang }}</span>
		</span>
		<vs-dropdown-menu class="w-48 i18n-dropdown vx-navbar-dropdown">
			<vs-dropdown-item @click="updateLocale('en')">
				<img class="h-4 w-5 mr-1 flag-icon" src="@/assets/images/flags/en.png" alt="en" />
				<span class="ml-2">English</span>
			</vs-dropdown-item>
			<vs-dropdown-item @click="updateLocale('id')">
				<img class="h-4 w-5 mr-1 flag-icon" src="@/assets/images/flags/id.png" alt="id" />
				<span class="ml-2">Bahasa Indonesia</span>
			</vs-dropdown-item>
			<!-- <vs-dropdown-item @click="updateLocale('fr')">
				<img class="h-4 w-5 mr-1" src="@/assets/images/flags/fr.png" alt="fr" /> &nbsp;French
			</vs-dropdown-item>
			<vs-dropdown-item @click="updateLocale('de')">
				<img class="h-4 w-5 mr-1" src="@/assets/images/flags/de.png" alt="de" /> &nbsp;German
			</vs-dropdown-item>
			<vs-dropdown-item @click="updateLocale('pt')">
				<img class="h-4 w-5 mr-1" src="@/assets/images/flags/pt.png" alt="pt" /> &nbsp;Portuguese
			</vs-dropdown-item> -->
		</vs-dropdown-menu>
	</vs-dropdown>
</template>

<script>
export default {
  computed: {
    i18nLocaleImg() {
      // eslint-disable-next-line
			return require(`@/assets/images/flags/${this.$i18n.locale}.png`);
    },
    getCurrentLocaleData() {
      let localeData = { flag: 'id', lang: 'Bahasa Indonesia' };
      const locale = this.$i18n.locale;
      if (locale === 'en') localeData = { flag: 'us', lang: 'English' };
      // if (locale == 'pt') return { flag: 'br', lang: 'Portuguese' };
      // if (locale == 'fr') return { flag: 'fr', lang: 'French' };
      // if (locale == 'de') return { flag: 'de', lang: 'German' };
      return localeData;
    },
  },
  methods: {
    updateLocale(locale) {
      this.$i18n.locale = locale;
      document.querySelector('html').setAttribute('lang', locale);
      this.$store.dispatch('setLocale', locale);
    },
  },
};
</script>
