<template>
	<div class="the-navbar__user-meta flex items-center" v-if="userName">
		<div class="text-right leading-tight hidden sm:block">
			<p class="font-semibold truncate" style="max-width: 130px">{{ userName }}</p>
			<small>{{ $t('Available') }}</small>
		</div>

		<vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
			<div class="con-img ml-3" v-if="!userProfile.picture">
				<!-- <vs-avatar class="user-profile-img main-nav-profile navbar-profile" color="#2BC1FF" text-color="white" :text="userProfile && userProfile.name ? userProfile.name : ''" size="40px" /> -->
				<div class="thumbnail-profile-dropdown">
					<div class="initial-profile-dropdown relative">{{generateInitial() }}</div>
				</div>
			</div>
			<div v-else class="con-img ml-3">
				<vs-avatar v-if="userProfile.picture" class="user-profile-img main-nav-profile" :src="userProfile && userProfile.picture ? userProfile.picture : ''" size="40px" />
			</div>

			<vs-dropdown-menu class="vx-navbar-dropdown profile-menu-dropdown">
				<ul class="profile-list-item">
					<li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click="$router.push('/profile').catch(() => {})">
						<feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
						<span class="ml-2">{{ $t('Profile') }}</span>
					</li>
					<!-- <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click="$router.push('/user-all-events').catch(() => {})">
						<feather-icon icon="ApertureIcon" svgClasses="w-4 h-4" />
						<span class="ml-2">{{ $t('All Event') }}</span>
					</li>
					<li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click="$router.push('/').catch(() => {})">
						<feather-icon icon="CalendarIcon" svgClasses="w-4 h-4" />
						<span class="ml-2">{{ $t('My Event') }}</span>
					</li> -->
					<vs-divider class="m-1" />

					<li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click="logout">
						<feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
						<span class="ml-2">Logout</span>
					</li>
				</ul>
			</vs-dropdown-menu>
		</vs-dropdown>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      user: 'user',
    }),
    userProfile() {
      const profile = this.user ? this.user : null;
      return profile;
    },
    userName() {
      const userName = this.user ? `${this.user.name}` : null;
      return userName;
    },
  },
  methods: {
    logout() {
      this.$router.push('/logout');
    },
    generateInitial() {
      const words = this.userProfile.name ? this.userProfile.name.split(' ') : 'AN';
      let initial = words[0][0] !== undefined ? words[0][0] : 'AN';
      if (words.length > 1) {
        const firstCharacter = words[0][0] !== undefined ? words[0][0] : '';
        // const secondCharacter = words[1][0] !== undefined ? words[1][0] : '';
        initial = `${firstCharacter}`;
      }
      return initial;
    },
  },
};
</script>
<style lang="scss">
.main-nav-profile .vs-avatar--con-img img {
    width: 50px;
	height:50px;
	object-fit:cover;
}
.navbar-profile .vs-avatar--text {
	font-size: 1.8em;
}
.thumbnail-profile-dropdown {
	position: relative;
	min-height: 40px;
	max-height: 40px;
	min-width: 40px;
	max-width: 40px;
	text-align: center;
	.initial-profile-dropdown {
		font-size: 1.3em;
		padding: 6px;
		min-width: 40px;
		max-width: 40px;
		margin: 0 auto;
		border-radius: 50%;
		background-color: #2BC1FF;
		color: #fff;
	}
}
</style>
