<template>
	<div
		class="layout--main"
		ref="layoutMain"
		:class="[layoutTypeClass, navbarClasses, footerClasses, {'no-scroll': isAppPage}]">

		<!-- <vx-tour
			:steps="steps"
			v-if="!disableThemeTour && (windowWidth >= 1200 && mainLayoutType === 'vertical' && verticalNavMenuWidth == 'default')"
		/> -->

		<!-- <the-customizer
			v-if="!disableCustomizer"
			:footerType="footerType"
			:hideScrollToTop="hideScrollToTop"
			:navbarType="navbarType"
			:navbarColor="navbarColor"
			:routerTransition="routerTransition"
			@toggleHideScrollToTop="toggleHideScrollToTop"
			@updateFooter="updateFooter"
			@updateNavbar="updateNavbar"
			@updateNavbarColor="updateNavbarColor"
			@updateRouterTransition="updateRouterTransition"
		/> -->

		<div
			v-if="(!isfetching) || (routeName !== 'userEvents' && (participant && participant.roles ? participant.roles : '' !== isModerator && isEventActive))">
			<v-nav-menu
				:navMenuItems="activeNavMenuItems"
				:header="navMenuHeader"
				:eventID="eventID"
				title="Kobla"
				parent=".layout--main"
			/>
		</div>

		<div
			v-if="(!isfetching) || (routeName !== 'userEvents' && (participant && participant.roles ? participant.roles : '' !== isModerator && isEventActive))" id="content-area" :class="[contentAreaClass, {'show-overlay': bodyOverlay}]">
			<div id="content-overlay" />

				<!-- Navbar -->
				<template v-if="mainLayoutType === 'horizontal' && windowWidth >= 1200">
					<the-navbar-horizontal
						:navbarType="navbarType"
						:class="[{'text-white' : isNavbarDark  && !isThemeDark}, {'text-base'  : !isNavbarDark && isThemeDark}]"
					/>

					<div style="height: 75px" v-if="navbarType === 'static'"></div>

					<h-nav-menu
						:class="[{'text-white' : isNavbarDark  && !isThemeDark}, {'text-base'  : !isNavbarDark && isThemeDark}]"
						:navMenuItems="activeNavMenuItems"
					/>
				</template>

				<template v-else>
					<the-navbar-vertical
						:navbarColor="navbarColor"
						:class="[{'text-white' : isNavbarDark  && !isThemeDark}, {'text-base'  : !isNavbarDark && isThemeDark}]"
					/>
				</template>
				<!-- /Navbar -->

			<div class="content-wrapper">
				<div class="router-view">
					<div class="router-content" >
						<!-- Content -->
						<div class="content-area__content">
							<back-to-top
								bottom="5%"
								:right="$vs.rtl ? 'calc(100% - 2.2rem - 38px)' : '30px'"
								visibleoffset="500"
								v-if="!hideScrollToTop">
								<vs-button icon-pack="feather" icon="icon-arrow-up" class="shadow-lg btn-back-to-top" />
							</back-to-top>
              <active-announcement
                  class="mt-12"
                  :isGlobal="true"
                />
							<transition :name="routerTransition" mode="out-in">
								<router-view
									@changeRouteTitle="changeRouteTitle"
									@setAppClasses="(classesStr) => $emit('setAppClasses', classesStr)"
								/>
							</transition>
						</div>
					</div>
				</div>
			</div>
			<the-footer />
		</div>

		<!-- conten area for User -->
		<div v-else id="content-area-full" :class="[contentAreaClass, {'show-overlay': bodyOverlay}]">
			<div id="content-overlay" />

				<!-- Navbar -->
				<template>
					<the-navbar-vertical-user
						:navbarColor="navbarColor"
						:class="[{'text-white' : isNavbarDark  && !isThemeDark}, {'text-base'  : !isNavbarDark && isThemeDark}]"
					/>
				</template>
				<!-- /Navbar -->

			<div class="content-wrapper">
				<div class="router-view" >
					<div class="router-content">
						<div class="content-area__content">
							<back-to-top
								bottom="5%"
								:right="$vs.rtl ? 'calc(100% - 2.2rem - 38px)' : '30px'"
								visibleoffset="500"
								v-if="!hideScrollToTop">
								<vs-button icon-pack="feather" icon="icon-arrow-up" class="shadow-lg btn-back-to-top" />
							</back-to-top>
							<transition v-if="!isfetching" :name="routerTransition" mode="out-in">
								<router-view
									@changeRouteTitle="changeRouteTitle"
									@setAppClasses="(classesStr) => $emit('setAppClasses', classesStr)"
								/>
							</transition>
						</div>
					</div>
				</div>
			</div>
		<the-footer />
		</div>
	</div>
</template>


<script>
import BackToTop from 'vue-backtotop';
import { mapActions, mapGetters } from 'vuex';
import HNavMenu from '@/layouts/components/horizontal-nav-menu/HorizontalNavMenu.vue';
import navMenuItems from '@/layouts/components/vertical-nav-menu/navMenuItems';
// import TheCustomizer from '@/layouts/components/customizer/TheCustomizer.vue';
import TheNavbarHorizontal from '@/layouts/components/navbar/TheNavbarHorizontal.vue';
import TheNavbarVertical from '@/layouts/components/navbar/TheNavbarVertical.vue';
import TheNavbarVerticalUser from '@/layouts/components/navbar/TheNavbarVerticalUser.vue';
import TheFooter from '@/layouts/components/TheFooter.vue';
import VNavMenu from '@/layouts/components/vertical-nav-menu/VerticalNavMenu.vue';
import themeConfig from '@/../themeConfig';
import authApi from '@/api/auth';
import eventsApi from '@/api/event';
// import participantApi from '@/api/participant';
import ActiveAnnouncement from '@/components/announcements/ActiveAnnouncement.vue';
import client from '../../lib/http-client';
import {
  isFunction,
  duplicateVar,
  generateMenuItems,
} from '../../lib/helper';

// const VxTour = () => import('@/components/VxTour.vue');

export default {
  components: {
    BackToTop,
    HNavMenu,
    // TheCustomizer,
    TheFooter,
    TheNavbarHorizontal,
    TheNavbarVertical,
    TheNavbarVerticalUser,
    VNavMenu,
    ActiveAnnouncement,
    // VxTour,
  },
  data() {
    return {
      disableCustomizer: themeConfig.disableCustomizer,
      disableThemeTour: themeConfig.disableThemeTour,
      dynamicWatchers: {},
      footerType: themeConfig.footerType || 'static',
      hideScrollToTop: themeConfig.hideScrollToTop,
      isNavbarDark: false,
      navbarColor: themeConfig.navbarColor || '#fff',
      navbarType: themeConfig.navbarType || 'static',
      navMenuItems,
      routerTransition: themeConfig.routerTransition || 'none',
      routeTitle: this.$route.meta.pageTitle,
      participant: null,
      isfetching: false,
      eventID: null,
      isModerator: '["moderator"]',
      steps: [
        {
          target: '#btnVNavMenuMinToggler',
          content: 'Toggle Collapse Sidebar.',
        },
        {
          target: '.vx-navbar__starred-pages',
          content:
						'Create your own bookmarks. You can also re-arrange them using drag & drop.',
        },
        {
          target: '.i18n-locale',
          content: 'You can change language from here.',
        },
        {
          target: '.navbar-fuzzy-search',
          content: 'Try fuzzy search to visit pages in flash.',
        },
        {
          target: '.customizer-btn',
          content: 'Customize template based on your preference',
          params: {
            placement: 'left',
          },
        },
        {
          target: '.vs-button.buy-now',
          content: 'Buy this awesomeness at affordable price!',
          params: {
            placement: 'top',
          },
        },
      ],
    };
  },
  watch: {
    $route() {
      this.routeTitle = this.$route.meta.pageTitle;

      // Fetch Event Details
      if (this.eventSlug) {
        this.fetchEvent();
      }
    },
    isThemeDark(val) {
      const color = this.navbarColor === '#fff' && val ? '#10163a' : '#fff';
      this.updateNavbarColor(color);
    },
    '$store.state.mainLayoutType': function (val) {
      this.setNavMenuVisibility(val);
      this.disableThemeTour = true;
    },
  },
  computed: {
    ...mapGetters({
      currentEvent: 'currentEvent',
      isSuperAdmin: 'isSuperAdmin',
      user: 'user',
    }),
    bodyOverlay() {
      return this.$store.state.bodyOverlay;
    },
    contentAreaClass() {
      if (this.mainLayoutType === 'vertical') {
        if (this.verticalNavMenuWidth === 'default') {
          return 'content-area-reduced';
        }
        if (this.verticalNavMenuWidth === 'reduced') {
          return 'content-area-lg';
        }
        return 'content-area-full';
      }
      // else if(this.mainLayoutType === "boxed") return "content-area-reduced"
      return 'content-area-full';
    },
    footerClasses() {
      return {
        'footer-hidden': this.footerType === 'hidden',
        'footer-sticky': this.footerType === 'sticky',
        'footer-static': this.footerType === 'static',
      };
    },
    isAppPage() {
      return this.$route.meta.no_scroll;
    },
    isThemeDark() {
      return this.$store.state.theme === 'dark';
    },
    layoutTypeClass() {
      return `main-${this.mainLayoutType}`;
    },
    mainLayoutType() {
      return this.$store.state.mainLayoutType;
    },
    navbarClasses() {
      return {
        'navbar-hidden': this.navbarType === 'hidden',
        'navbar-sticky': this.navbarType === 'sticky',
        'navbar-static': this.navbarType === 'static',
        'navbar-floating': this.navbarType === 'floating',
      };
    },
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth;
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    breadcrumbs() {
      let links = [];
      if (Array.isArray(this.$route.meta.breadcrumb)) {
        links = this.$route.meta.breadcrumb;
      } else if (isFunction(this.$route.meta.breadcrumb)) {
        links = this.$route.meta.breadcrumb(this.$route);
      }
      return links;
    },
    isEventActive() {
      const path = this.$route.path;
      return path.includes('/events/');
    },
    eventSlug() {
      let eventSlug = null;
      if (this.isEventActive) {
        eventSlug = this.$route.params.eventSlug || this.$route.params.slug;
      }
      return eventSlug;
    },
    activeNavMenuItems() {
      let menuItems = duplicateVar(this.navMenuItems);
      if (this.isEventActive) {
        menuItems = generateMenuItems(this.eventSlug);
      }
      return menuItems;
    },
    navMenuHeader() {
      const titleLength = 60;
      const eventName = this.currentEvent ? this.currentEvent.name : '';
      const link = `/events/${this.eventSlug}`;
      const accessCode = this.currentEvent ? this.currentEvent.code : '';
      return this.eventSlug ? {
        type: 'Event',
        title: eventName && eventName.length > titleLength ? `${eventName.substring(0, titleLength)}...` : eventName,
        link,
        accessCode,
      } : null;
    },
    routeName() {
      return this.$route.name;
    },
    room() {
      return 'global';
    },
  },
  methods: {
    ...mapActions({
      setCurrentEvent: 'setCurrentEvent',
    }),
    fetchEvent() {
      const callback = (response) => {
        const eventDetails = response.data;
        this.setCurrentEvent(eventDetails);
        this.eventID = eventDetails.id;
      };
      const errorCallback = () => {
      };
      eventsApi.getBySlug(this.eventSlug, callback, errorCallback);
    },
    changeRouteTitle(title) {
      this.routeTitle = title;
    },
    updateNavbar(val) {
      if (val === 'static') {
        this.updateNavbarColor(this.isThemeDark ? '#10163a' : '#fff');
      }
      if (!val) {
        val = 'floating';
      }
      this.navbarType = val;
    },
    updateNavbarColor(val) {
      this.navbarColor = val;
      if (val === '#fff') this.isNavbarDark = false;
      else this.isNavbarDark = true;
    },
    updateFooter(val) {
      this.footerType = val;
    },
    updateRouterTransition(val) {
      this.routerTransition = val;
    },
    setNavMenuVisibility(layoutType) {
      if (
        (layoutType === 'horizontal' && this.windowWidth >= 1200)
				|| (layoutType === 'vertical' && this.windowWidth < 1200)
      ) {
        this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', false);
        this.$store.dispatch(
          'updateVerticalNavMenuWidth',
          'no-nav-menu',
        );
      } else {
        this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true);
      }
    },
    toggleHideScrollToTop(val) {
      this.hideScrollToTop = val;
    },
    fetchUser() {
      this.$vs.loading();
      this.isfetching = true;
      const callback = (response) => {
        const user = response.data;
        const userRoles = JSON.parse(user.roles);
        const userRole = userRoles[0];
        user.role = userRole;
        const moderator = localStorage.getItem('is_moderator');
        if (moderator) {
          user.name = 'Moderator';
        }
        this.$store.dispatch('setUser', user);

        // set language
        client.defaults.headers['Accept-Language'] = response.data.language || 'no';
        this.$i18n.locale = response.data.language || 'no';
        this.$store.dispatch('setLocale', response.data.language || 'no');

        this.isfetching = false;
        this.$vs.loading.close();
      };
      authApi.getProfile(callback);
    },
    initSocket() {
      // Join Room
      this.$socket.client.emit('join', this.room);
    },
  },
  created() {
    this.initSocket();
    // Fetch User Details
    this.fetchUser();

    // Fetch Event Details
    if (this.eventSlug) {
      this.fetchEvent();
    }

    const color = this.navbarColor === '#fff' && this.isThemeDark
      ? '#10163a'
      : this.navbarColor;
    this.updateNavbarColor(color);
    this.setNavMenuVisibility(this.$store.state.mainLayoutType);

    // Dynamic Watchers for tour
    // Reason: Once tour is disabled it is not required to enable it.
    // So, watcher is required for just disabling it.
    this.dynamicWatchers.windowWidth = this.$watch(
      '$store.state.windowWidth',
      (val) => {
        if (val < 1200) {
          this.disableThemeTour = true;
          this.dynamicWatchers.windowWidth();
        }
      },
    );

    this.dynamicWatchers.verticalNavMenuWidth = this.$watch(
      '$store.state.verticalNavMenuWidth',
      () => {
        this.disableThemeTour = true;
        this.dynamicWatchers.verticalNavMenuWidth();
      },
    );

    this.dynamicWatchers.rtl = this.$watch('$vs.rtl', () => {
      this.disableThemeTour = true;
      this.dynamicWatchers.rtl();
    });
  },
  beforeDestroy() {
    Object.keys(this.dynamicWatchers).forEach((i) => {
      this.dynamicWatchers[i]();
      delete this.dynamicWatchers[i];
    });
    const isModeratorGuest = localStorage.getItem('is_moderator') === 'true';
    if (isModeratorGuest) {
      localStorage.setItem('is_moderator', false);
      this.$store.dispatch('logout');
    }
  },
};
</script>
