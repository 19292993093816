<template>
  <footer class="the-footer flex-wrap justify-between" :class="classes">
      <p>
    <span>{{ $t('Copyright') }} &copy;</span>
    <span>{{ new Date().getFullYear() }} </span>
    <a href="https://www.lomedia.no" target="_blank" rel="nofollow">LO Media</a>
    <span class="hidden sm:inline-block">, {{ $t('All rights Reserved') }}</span>
      </p>
      <span class="md:flex hidden items-center">
    <span>
      <a class="pointer" href="/privacy-policy">{{ $t('Privacy Policy') }}</a> | <a class="pointer" href="/terms-and-conditions">{{ $t('Terms and Conditions') }}</a>
    </span>
      </span>
  </footer>
</template>

<script>
export default {
  name: 'TheFooter',
  props: {
    classes: {
      type: String,
    },
  },
};
</script>
